import { StoreConfig, Store } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { RemoteConfigModel } from '../models/remote-config.model';
import { CardPaymentProviders } from '../../payments/models/payment-providers.model';
import { FeatureFeaturedConfig } from '../models/feature-featured-config.model';
import { CardOnlyDiscountCodes } from '../models/card-only-discount-codes.model';
import { ExperimentCashOnDeliveryMode } from '../models/experiment-cod.enum';
import { FeaturedBanner } from '../models/featured-banner.model';
import { DeliveryEstimate } from '../models/delivery-estimate.model';
import { SharedImageCategory } from '../models/shared-category-images.model';
import { NewsletterSubtitle } from '../models/newsletter-subtitle.model';
import { PopularDevices } from '../models/popular-devices.model';
import { FeatureBestSellers } from '../models/feature-best-sellers';
import { BestSellersPerCategory } from '../models/best-sellers-per-category.model';

export const DEFAULT_DELIVERY_ESTIMATES = [
  {
    country: 'BR',
    values: { minDays: 7, maxDays: 21 },
  },
  {
    country: 'US',
    values: { minDays: 7, maxDays: 7 },
  },
  {
    country: 'SA',
    values: { minDays: 5, maxDays: 7 },
  },
  {
    country: 'AE',
    values: { minDays: 5, maxDays: 7 },
  },
];

export interface AkitaRemoteConfigState {
  featureDeliveryEnabled: boolean; // @deprecated: Loggi V1
  featureDeliveryValidationBypass: boolean;
  featureDeliveryEnabledLoggi: boolean;
  featureAppUpdate: boolean; // Flag to check in-app updates on app start.
  payloadPriorityInfo: string; // A json that if valid, will show a card on home screen.
  leaveDeliverySurveyQuestion: string;
  featureFeatured: FeatureFeaturedConfig | null;
  featuredBanner: FeaturedBanner | null;
  cardOnlyDiscountCodes: Array<CardOnlyDiscountCodes>;
  deliveryEstimates: Array<DeliveryEstimate>;
  currentConditionSet: string | null;
  featureCashOnDelivery: boolean;
  experimentCashOnDelivery: ExperimentCashOnDeliveryMode | null;
  experimentCheckout: string | null;
  tamaraPayNowExperiment: string | null;

  paymentProvider: CardPaymentProviders | null;
  checkoutFlow: string | null;
  expressCheckout: boolean;
  addressSearch: boolean;

  popularDevices: PopularDevices | null;
  bestSellersPerCategory: BestSellersPerCategory | null;
  bestProductsTamaraTest: BestSellersPerCategory | null;
  featureBestSellers: FeatureBestSellers | null;
  testProductPage: string;
  sharedImageCategory: SharedImageCategory | null;
  newsletterSubtitle: NewsletterSubtitle | null;

  sendWebReviewsTo: string | null;

  isLoading: boolean;
  error: any | null;
}

export function createInitialState(): AkitaRemoteConfigState {
  return {
    featureDeliveryEnabled: false, // @deprecated: Loggi V1
    featureDeliveryValidationBypass: false,
    featureDeliveryEnabledLoggi: true,
    featureAppUpdate: false,
    payloadPriorityInfo: '',
    leaveDeliverySurveyQuestion: '',
    featureFeatured: null,
    featuredBanner: null,
    cardOnlyDiscountCodes: new Array(0),
    deliveryEstimates: DEFAULT_DELIVERY_ESTIMATES,
    currentConditionSet: null,
    featureCashOnDelivery: false,
    experimentCashOnDelivery: null,
    experimentCheckout: null,
    paymentProvider: null,
    isLoading: false,
    error: null,
    tamaraPayNowExperiment: null,
    checkoutFlow: null,
    expressCheckout: true,
    popularDevices: null,
    bestSellersPerCategory: null,
    bestProductsTamaraTest: null,
    featureBestSellers: null,
    sharedImageCategory: null,
    newsletterSubtitle: null,
    addressSearch: true,
    testProductPage: 'original',
    sendWebReviewsTo: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'remote-config', resettable: true })
export class AkitaRemoteConfigStore extends Store<AkitaRemoteConfigState> {
  constructor() {
    super(createInitialState());
  }

  public updateConfig(config: Partial<RemoteConfigModel>): void {
    this.update({
      leaveDeliverySurveyQuestion: config.leaveDeliverySurveyQuestion,
      featureFeatured: config.featureFeatured,
      featuredBanner: config.featuredBanner,
      // cardOnlyDiscountCodes: config.cardOnlyDiscountCodes,
      deliveryEstimates: DEFAULT_DELIVERY_ESTIMATES,
      featureDeliveryEnabled: config.featureDeliveryEnabled, // @deprecated: Loggi V1
      featureDeliveryValidationBypass: config.featureDeliveryValidationBypass,
      featureDeliveryEnabledLoggi: config.featureDeliveryEnabledLoggi,
      featureAppUpdate: config.featureAppUpdate,
      payloadPriorityInfo: config.payloadPriorityInfo,
      currentConditionSet: config.currentConditionSet,
      popularDevices: config.popularDevices,
      bestSellersPerCategory: config.bestSellersPerCategory,
      bestProductsTamaraTest: config.bestProductsTamaraTest,
      featureBestSellers: config.featureBestSellers,
      sharedImageCategory: config.sharedImageCategory,
      newsletterSubtitle: config.newsletterSubtitle,
      testProductPage: config.testProductPage || 'original',
      sendWebReviewsTo: config.sendWebReviewsTo,
      // featureCashOnDelivery: config.featureCashOnDelivery,
    });
  }

  public updatePaymentProvider(paymentProvider: CardPaymentProviders): void {
    this.update({
      paymentProvider: paymentProvider,
    });
  }

  public updateCheckoutFlow(checkoutFlow: string | null): void {
    this.update({
      checkoutFlow: checkoutFlow,
    });
  }

  public updateAddressSearch(addressSearch: boolean): void {
    this.update({
      addressSearch: addressSearch,
    });
  }

  public updateExpressCheckout(experimentCheckout: boolean): void {
    this.update({
      expressCheckout: experimentCheckout,
    });
  }

  public updateExperimentCheckout(experimentCheckout: string | null): void {
    this.update({
      experimentCheckout: experimentCheckout,
    });
  }

  public updateLeaveOrderSurveyQuestion(question?: string | null): void {
    this.update({
      leaveDeliverySurveyQuestion: `${question || ''}`,
    });
  }

  public updateTamaraPayNowExperiment(value?: string | null): void {
    this.update({
      tamaraPayNowExperiment: value || null,
    });
  }

  public updateFeatureCashOnDelivery(shouldEnable?: boolean | null): void {
    this.update({
      featureCashOnDelivery: Boolean(shouldEnable !== false),
    });
  }

  public updateExperimentCashOnDelivery(
    mode?: ExperimentCashOnDeliveryMode | null
  ): void {
    this.update({
      experimentCashOnDelivery: mode || null,
    });
  }

  public updateCardOnlyDiscountCodes(
    discounts?: Array<CardOnlyDiscountCodes> | null
  ): void {
    this.update({
      cardOnlyDiscountCodes: discounts || new Array(0),
    });
  }

  public setLoading(isLoading: boolean): void {
    this.update({
      isLoading: isLoading,
    });
  }

  public setError(error: unknown): void {
    this.update({
      error: error,
    });
  }
}
