import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { combineLatest, Observable, map, distinctUntilChanged } from 'rxjs';
import {
  AkitaRouterState,
  PopsyViewStep,
  PopsyViewName,
  RouteInformation,
  AnalyticsMetadata,
} from '@app/akita/router/models/router.state';
import { Params } from '@angular/router';
import { AkitaRouterStore } from '@app/akita/router/state/router.store';
import {
  generateURL,
  getBaseCanonicalURL,
  getLangSubdomain,
} from '@app/shared/utils/url.utils';
import { PriceModel } from '@app/shared/models/api/price.model';
import { AkitaLocationQuery } from '@app/akita/api/location/state/location.query';
import { DiscountInfo } from '../models/discount-info.model';
import { getCountryCodeFromDomainURL } from '@app/shared/data/subdomains.data';
import { DEFAULT_AVAILABLE_COUNTRIES } from '@app/akita/api/products/models/products.state';

const LISTING_ID_LENGTH = 24;

@Injectable({ providedIn: 'root' })
export class AkitaRouterQuery extends Query<AkitaRouterState> {
  constructor(
    protected readonly store: AkitaRouterStore,
    protected readonly akitaLocationQuery: AkitaLocationQuery
  ) {
    super(store);
  }

  public get country(): string {
    const countryQuery = `${this.queryParams.country || ''}`.toUpperCase();
    const countryData = `${this.routeData.country || ''}`.toUpperCase();
    const locationCountry = `${
      this.akitaLocationQuery.currentLocation?.country || ''
    }`.toUpperCase();

    return this.getCountryFromData(countryQuery, countryData, locationCountry);
  }

  public selectCountry(): Observable<string> {
    return combineLatest([
      this.selectQueryParams(),
      this.selectRouteData(),
      this.akitaLocationQuery.selectDeviceLocation(),
    ]).pipe(
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      map(([queryParams, routeData, currentLocation]) => {
        const countryQuery = `${queryParams?.country || ''}`.toUpperCase();
        const countryData = `${routeData?.country || ''}`.toUpperCase();
        const locationCountry = `${currentLocation?.country || ''}`.toUpperCase();

        return this.getCountryFromData(countryQuery, countryData, locationCountry);
      })
    );
  }

  private getCountryFromData(
    countryQuery: string,
    countryData: string,
    locationCountry: string
  ): string {
    // Get the country from the Domain
    const countryDomain = `${
      getCountryCodeFromDomainURL(this.requestURL) || ''
    }`.toUpperCase();

    // Get the country from the Sub-domain
    const countrySubdomain = `${
      getLangSubdomain(this.requestURL, true) || ''
    }`.toUpperCase();

    // If the Subdomain is set, or a query param / param is set, the country gets inferred from that
    let country = countryData;
    if (countryQuery) {
      country = countryQuery; // query param has priority
    } else if (countrySubdomain) {
      country = countrySubdomain; // subdomain 2nd has priority
    } else if (countryDomain) {
      country = countryDomain; // domain 3rd has priority
    } else if (locationCountry) {
      if (locationCountry.includes('US') || locationCountry.includes('CA')) {
        country = 'AE'; // default to UAE if location is in the US or Canada
      } else {
        country = DEFAULT_AVAILABLE_COUNTRIES.includes(locationCountry)
          ? locationCountry // default to location country if it's in the list
          : 'SA'; // default if location is not in the list
      }
    }

    return country;
  }

  public get requestURL(): string {
    return this.store.requestURL;
  }

  public get isBrowser(): boolean {
    return Boolean(this.store.isBrowser);
  }

  public getCanonicalBaseURL(country?: string | null): string {
    return getBaseCanonicalURL(country, this.requestURL);
  }

  public get isRtlLanguage(): boolean {
    return Boolean(this.getValue().rtl);
  }

  public get fragment(): string {
    return this.getValue().fragment || '';
  }

  public get queryParams(): Params {
    return this.getValue().queryParams || {};
  }

  public get params(): Params {
    return this.getValue().params || {};
  }

  public get paramsAndQueryParams(): Params {
    return {
      ...this.params,
      ...this.queryParams,
    };
  }

  public getQueryParamsForPaymentRedirectURLs(addressId?: string | null): string {
    const queryParamsList = new Array(0);
    if (addressId) {
      queryParamsList.push(`address_id=${addressId}`);
    }

    const discountCode = `${this.discountCode || ''}`.toUpperCase();
    if (discountCode) {
      queryParamsList.push(`dc=${discountCode}`);
    }

    const queryParamsToKeep = this.queryParamsToKeep;
    for (const paramName of Object.keys(queryParamsToKeep)) {
      if (paramName === 'dc' && discountCode) {
        continue;
      }

      queryParamsList.push(`${paramName}=${queryParamsToKeep[paramName]}`);
    }

    const queryParams = queryParamsList.length > 0 ? `${queryParamsList.join('&')}` : '';

    return encodeURI(queryParams);
  }

  public get queryParamsToKeep(): Params {
    return { ...(this.getValue().queryParamsToKeep || {}) };
  }

  public get discountCode(): string {
    return `${this.getValue().discountCode || ''}`;
  }

  public get routeData(): Params {
    return this.getValue().data || {};
  }

  public get redirect(): string {
    return this.getValue().redirect || '';
  }

  public get viewName(): PopsyViewName {
    return this.getValue().view;
  }

  public get viewStep(): PopsyViewStep {
    return this.getValue().step;
  }

  public get analyticsName(): string {
    return `${this.getValue().data?.analyticsName || ''}`;
  }

  public get googleADSMetadata(): AnalyticsMetadata {
    return this.getValue().analyticsMetadata;
  }

  public get homeUrlFragment(): Array<string> {
    return [...(this.getValue().routeFragments?.homeUrlFragment || ['/'])];
  }

  public get searchUrlFragment(): Array<string> {
    return [...this.homeUrlFragment, 's'];
  }

  public get userAgent(): string {
    return this.getValue().userAgent || '';
  }

  public get isGoogleBotUserAgent(): boolean {
    return this.userAgent.toLowerCase().includes('google');
  }

  public getDiscountForCurrency(currency?: string | null): PriceModel | null {
    const discountCode = this.getValue().discountCode || null;
    if (currency && discountCode) {
      const discountCodeValues = this.getValue().discountCodeValues[discountCode] || {};
      return discountCodeValues[currency] || null;
    } else {
      return null;
    }
  }

  public getDiscountCodeInfo(
    discountCode?: string | null,
    currency?: string | null
  ): DiscountInfo | null {
    if (
      discountCode &&
      this.getValue().discountCodeValues &&
      this.getValue().discountCodeValues[discountCode][currency || '']
    ) {
      return {
        code: discountCode,
        amount:
          this.getValue().discountCodeValues[discountCode][currency || '']?.amount || 0,
        currency:
          this.getValue().discountCodeValues[discountCode][currency || '']?.currency ||
          currency ||
          '',
      };
    }
    return null;
  }

  public getListingUrlFragment(id: string, title?: string | null): Array<string> {
    const fragment = this.homeUrlFragment;
    if (`${id || ''}`.length < LISTING_ID_LENGTH) {
      fragment.push('p');
    } else {
      fragment.push('item');
    }
    if (title) {
      fragment.push(generateURL(id, title));
    } else {
      fragment.push(id);
    }
    return fragment;
  }

  public getDeliveryUrlFragment(path: 'new' | string): Array<string> {
    const fragment = this.homeUrlFragment;
    fragment.push('delivery', path);
    return fragment;
  }

  public getCheckoutUrlFragment(sessionId: 'XXX' | string): Array<string> {
    const fragment = this.homeUrlFragment;
    fragment.push('checkout', sessionId, 'overview');
    return fragment;
  }

  public getPhoneSearchFragment(): Array<string> {
    const fragment = this.homeUrlFragment;
    fragment.push(`products`);

    return fragment;
  }

  public get userUrlFragment(): Array<string> {
    const fragment = this.homeUrlFragment;
    fragment.push('user');
    return fragment;
  }

  public getSettingsUrlFragment(path?: 'user' | 'payment' | 'address'): Array<string> {
    const fragment = this.homeUrlFragment;
    fragment.push('settings');
    if (path) {
      fragment.push(path);
    }
    return fragment;
  }

  public getAuthUrlFragment(path?: 'sign-in' | 'sign-up' | null): Array<string> {
    const fragment = this.userUrlFragment;
    fragment.push(path || 'sign-in');
    return fragment;
  }

  public get userInboxUrlFragment(): Array<string> {
    const fragment = this.userUrlFragment;
    fragment.push('inbox');
    return fragment;
  }

  public monitorNavigationEnd(): Observable<RouteInformation> {
    return this.select().pipe(
      map((state: AkitaRouterState) => ({
        params: state.params || {},
        queryParams: state.queryParams || {},
        data: state.data || {},
        fragment: state.fragment || '',
      })),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectHomeUrlFragment(): Observable<Array<string>> {
    return this.select().pipe(
      map((state: AkitaRouterState) => [
        ...(state.routeFragments.homeUrlFragment || ['/']),
      ]),
      distinctUntilChanged()
    );
  }

  public selectSearchUrlFragment(): Observable<Array<string>> {
    return this.selectHomeUrlFragment().pipe(
      map((fragment: Array<string>) => [...(fragment || []), 's']),
      distinctUntilChanged()
    );
  }

  public selectUserUrlFragment(
    path?: 'notifications' | 'inbox'
  ): Observable<Array<string>> {
    return this.selectHomeUrlFragment().pipe(
      map((homeUrlFragment: Array<string>) => {
        const fragment = [...homeUrlFragment];
        fragment.push('user');

        if (path) {
          fragment.push(path);
        }

        return fragment;
      })
    );
  }

  public selectUserNotificationsUrlFragment(): Observable<Array<string>> {
    return this.selectUserUrlFragment('notifications');
  }

  public selectUserInboxUrlFragment(): Observable<Array<string>> {
    return this.selectUserUrlFragment('inbox');
  }

  public selectDeliveryUrlFragment(path: 'new' | string): Observable<Array<string>> {
    return this.selectHomeUrlFragment().pipe(
      map((homeUrlFragment: Array<string>) => {
        const fragment = [...homeUrlFragment];
        fragment.push('delivery', path);
        return fragment;
      }),
      distinctUntilChanged()
    );
  }

  public selectUserProfileUrlFragment(): Observable<Array<string>> {
    return this.selectHomeUrlFragment().pipe(
      map((homeUrlFragment: Array<string>) => {
        const fragment = [...homeUrlFragment];
        fragment.push('user');
        return fragment;
      }),
      distinctUntilChanged()
    );
  }

  public selectIsRtlLayout(): Observable<boolean> {
    return this.select().pipe(map((state: AkitaRouterState) => Boolean(state.rtl)));
  }

  public selectInFullScreenMode(): Observable<boolean> {
    return this.select().pipe(
      map((state: AkitaRouterState) => Boolean(state.queryParams?.fs))
    );
  }

  public selectAnalyticsName(): Observable<string> {
    return this.select().pipe(
      map((state: AkitaRouterState) => `${state.data?.analyticsName || ''}`)
    );
  }

  public selectIsInternalView(): Observable<boolean> {
    return this.select().pipe(
      map((state: AkitaRouterState) => Boolean(state.internalView))
    );
  }

  public selectRedirect(): Observable<string> {
    return this.select().pipe(map((state: AkitaRouterState) => state.redirect));
  }

  public selectQueryParams(): Observable<Params> {
    return this.select().pipe(
      map((state: AkitaRouterState) => state.queryParams || {}),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectParamsAndQueryParams(): Observable<Params> {
    return this.select().pipe(
      map((state: AkitaRouterState) => ({
        ...(state.params || {}),
        ...(state.queryParams || {}),
      })),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectRouteData(): Observable<Params> {
    return this.select().pipe(
      map((state: AkitaRouterState) => state.data || {}),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectQueryParamsToKeep(): Observable<Params> {
    return this.select().pipe(
      map((state: AkitaRouterState) => state.queryParamsToKeep || {}),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectViewName(): Observable<PopsyViewName> {
    return this.select().pipe(
      map((state: AkitaRouterState) => state.view || 'ROOT'),
      distinctUntilChanged()
    );
  }

  public selectViewStep(): Observable<PopsyViewStep> {
    return this.select().pipe(
      map((state: AkitaRouterState) => state.step || ''),
      distinctUntilChanged()
    );
  }

  public selectPhoneSearchFragment(): Observable<Array<string>> {
    return this.selectHomeUrlFragment().pipe(
      map((homeFragment: Array<string>) => [...(homeFragment || []), 'products']),
      distinctUntilChanged()
    );
  }

  public selectDiscountForCurrency(
    currency?: string | null
  ): Observable<PriceModel | null> {
    return this.select().pipe(
      map((state: AkitaRouterState) => {
        const discountCode = state.discountCode || null;
        if (currency && discountCode) {
          const discountCodeValues = state.discountCodeValues[discountCode] || {};
          return discountCodeValues[currency] || null;
        } else {
          return null;
        }
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }
}
