import { Injectable } from '@angular/core';
import { AkitaProductsQuery } from '@app/akita/api/products/state/products.query';
import { Query } from '@datorama/akita';
import { Observable, distinctUntilChanged, map, combineLatest } from 'rxjs';
import { CartItem } from '../models/cart.model';
import { AkitaCartState } from '../models/cart.state.model';
import { AkitaCartStore } from './cart.store';
import { PriceModel } from '@app/shared/models/api/price.model';
import { Insurance } from '@app/akita/api/products/models/insurance.model';

@Injectable({ providedIn: 'root' })
export class AkitaCartQuery extends Query<AkitaCartState> {
  constructor(
    protected store: AkitaCartStore,
    protected akitaProductQuery: AkitaProductsQuery
  ) {
    super(store);
  }

  public getId(): string | null {
    const country = this.akitaProductQuery.validateLocale().country;
    return this.getValue()[country] ? this.getValue()[country].id : null;
  }

  public getIsLocked(): boolean {
    const country = this.akitaProductQuery.validateLocale().country;
    return this.getValue()[country] ? this.getValue()[country].isLocked : false;
  }

  public getSessionId(): string | null {
    const country = this.akitaProductQuery.validateLocale().country;
    return this.getValue()[country] ? this.getValue()[country].sessionId : null;
  }

  public getLastSessionId(): string | null {
    const country = this.akitaProductQuery.validateLocale().country;
    return this.getValue()[country] ? this.getValue()[country].lastSessionId : null;
  }

  public getCartItems(): Array<CartItem> {
    const country = this.akitaProductQuery.validateLocale().country;
    return this.getValue()[country] ? this.getValue()[country].items || [] : [];
  }

  public selectId(): Observable<string | null> {
    return combineLatest([this.select(), this.akitaProductQuery.selectCountry()]).pipe(
      map(([state, country]) => (state[country] ? state[country].id : null)),
      distinctUntilChanged()
    );
  }

  public selectLastSessionId(): Observable<string | null> {
    return combineLatest([this.select(), this.akitaProductQuery.selectCountry()]).pipe(
      map(([state, country]) => (state[country] ? state[country].lastSessionId : null)),
      distinctUntilChanged()
    );
  }

  public selectLoadingCartItems(): Observable<boolean> {
    return combineLatest([this.select(), this.akitaProductQuery.selectCountry()]).pipe(
      map(([state, country]) =>
        Boolean(state[country] ? state[country].loadingCartItems : false)
      ),
      distinctUntilChanged()
    );
  }

  public selectUpdatingCart(): Observable<boolean> {
    return combineLatest([this.select(), this.akitaProductQuery.selectCountry()]).pipe(
      map(([state, country]) =>
        Boolean(state[country] ? state[country].updatingCart : false)
      ),
      distinctUntilChanged()
    );
  }

  public selectSavingCartItems(): Observable<boolean> {
    return combineLatest([this.select(), this.akitaProductQuery.selectCountry()]).pipe(
      map(([state, country]) =>
        Boolean(state[country] ? state[country].savingCartItems : false)
      ),
      distinctUntilChanged()
    );
  }

  public selectCartItems(): Observable<Array<CartItem>> {
    return combineLatest([this.select(), this.akitaProductQuery.selectCountry()]).pipe(
      map(([state, country]) =>
        state[country] ? state[country].items || [] : new Array(0)
      )
    );
  }

  public selectTotalPrice(): Observable<PriceModel> {
    return combineLatest([this.select(), this.akitaProductQuery.selectCountry()]).pipe(
      map(([state, country]) => {
        const items = state[country].items || [];
        const packagePrice = new PriceModel(); /* state[country].isProtected
          ? state[country].protectionPrice
          : {
              amount: 0,
              currency: 'SAR',
            }; */
        const itemsPrice = items.reduce(
          (acc: PriceModel, item) => {
            acc.amount +=
              ((item.product.price.amount || 0) + (item?.insurance?.price?.amount || 0)) *
              (item?.quantity || 1);
            acc.currency = item.product.price.currency;
            return acc;
          },
          { amount: 0, currency: 'SAR' } as PriceModel
        );
        return {
          amount: itemsPrice.amount + (packagePrice?.amount || 0),
          currency: itemsPrice.currency,
        } as PriceModel;
      }),
      distinctUntilChanged((a, b) => a === b)
    );
  }

  public selectCartInsurances(): Observable<Array<Insurance>> {
    return combineLatest([this.select(), this.akitaProductQuery.selectCountry()]).pipe(
      map(([state, country]) => (state[country] ? state[country].insurances : [])),
      distinctUntilChanged((a, b) => a === b)
    );
  }

  public selectProtectionPrice(): Observable<PriceModel | null> {
    return combineLatest([this.select(), this.akitaProductQuery.selectCountry()]).pipe(
      map(([state, country]) => state[country].protectionPrice || null),
      distinctUntilChanged((a, b) => a === b)
    );
  }

  public selectIsCartProtected(): Observable<boolean> {
    return combineLatest([this.select(), this.akitaProductQuery.selectCountry()]).pipe(
      map(([state, country]) => Boolean(state[country].isProtected)),
      distinctUntilChanged()
    );
  }

  public getIdsFragmentFromCart(): string {
    let result = '';
    this.getValue()[this.akitaProductQuery.validateLocale().country].items.forEach(
      (a, index) => {
        if (index > 0) {
          result = result.concat('-');
        }
        for (let quantity = 1; quantity <= a.quantity; quantity++) {
          if (quantity > 1) {
            result = result.concat('-');
          }
          result = result.concat(a.product.id);
        }
      }
    );
    return result;
  }
}
