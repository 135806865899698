import { Injectable, NgZone } from '@angular/core';
import { Product } from '@app/akita/api/products/models/product.model';
import { AkitaProductsQuery } from '@app/akita/api/products/state/products.query';
import {
  logAddInsuranceToCart,
  logAddToCart,
  logRemoveFromCart,
  logRemoveInsuranceFromCart,
} from '@app/core/services/google-analytics.lazy';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';
import { parseApiError } from '@app/shared/models/api/api-error.model';
import { applyTransaction, logAction } from '@datorama/akita';
import {
  Observable,
  Subscription,
  of,
  throwError,
  catchError,
  tap,
  switchMap,
} from 'rxjs';
import { Cart } from '../models/cart-multiple.model';
import { CartItem } from '../models/cart.model';
import { CartApiService } from '../service/cart-api.service';
import { AkitaCartStore } from './cart.store';
import { Insurance } from '@app/akita/api/products/models/insurance.model';
import { PackageProtectionModel } from '@app/akita/api/checkout/models/package-protection.model';

const FAILED_TO_SAVE_CART_ERROR = 'FAILED_TO_SAVE_CART';

@Injectable({ providedIn: 'root' })
export class AkitaCartService {
  constructor(
    private readonly zone: NgZone,
    private readonly store: AkitaCartStore,
    private readonly analytics: GoogleAnalyticsService,
    private readonly akitaProductQuery: AkitaProductsQuery,
    private readonly cartApiService: CartApiService
  ) {}

  public reset(): void {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('reset()');
        this.store.reset();
      });
    });
  }

  public transferAnonymousCartAsync(): Subscription {
    return this.transferAnonymousCart().subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public transferAnonymousCart(): Observable<Cart | null> {
    const country = this.akitaProductQuery.validateLocale().country;
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('transferAnonymousCart()');
        this.store.toggleTransferingAnonymousCart(country, true);
        this.store.setTransferingAnonymousCartError(null);
      });
    });

    return this.cartApiService.transferAnonymousCart().pipe(
      catchError((err: unknown) => {
        const parsed = parseApiError(err);
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('transferAnonymousCart() - error');
            this.store.toggleTransferingAnonymousCart(country, false);
            this.store.setTransferingAnonymousCartError(country, parsed);
          });
        });
        return throwError(() => parsed);
      }),
      tap((cart: Cart | null) => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('transferAnonymousCart() - done');
            this.store.toggleTransferingAnonymousCart(country, false);
            this.store.setTransferingAnonymousCartError(null);

            if (cart) {
              this.store.updateCartId(country, cart.id);
              this.store.setCartItems(country, cart.orders);
            }
          });
        });
      })
    );
  }

  public addNewProductToCartAsync(
    product: Product,
    insurance?: Insurance | null
  ): Subscription {
    return this.addNewProductToCart(product, insurance).subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public addNewProductToCart(
    product: Product,
    insurance?: Insurance | null,
    trigger?: string
  ): Observable<boolean> {
    const country = this.akitaProductQuery.validateLocale(null, product.country).country;

    this.analytics.setSessionUserDetailsIntoDataLayer();
    logAddToCart(
      this.analytics.logEventWrapper,
      this.analytics.firebaseRecordAddToCartADConversion,
      this.analytics.facebookTrackAddToCart,
      product,
      '?'
    );

    if (insurance) {
      insurance.productId = product.id;
      logAddInsuranceToCart(
        this.analytics.logEventWrapper,
        insurance,
        trigger || 'product-page'
      );
    }

    // new cart on country change
    if (!this.store.getValue()[country] || !this.store.getValue()[country].id) {
      return this.addProductToCleanCart(product, insurance);
    }

    const storeItems = this.store.getValue()[country].items;
    let insurances: Array<Insurance> = JSON.parse(
      JSON.stringify(this.store.getValue()[country].insurances)
    );

    try {
      const existIndex = storeItems.findIndex((item) => item.product.id === product.id);
      if (existIndex !== -1) {
        storeItems[existIndex].quantity++;
        if (
          insurance &&
          insurances.findIndex((i) => i.productId === insurance.productId) !== -1
        ) {
          insurances = insurances?.map((i) => {
            if (i.productId === insurance.productId) {
              insurance.quantity = storeItems[existIndex].quantity;
              // targetItem.insurance = insurance;
              i = insurance;
            }
            return i;
          });
        } else if (insurance) {
          insurance.quantity = storeItems[existIndex].quantity;
          storeItems[existIndex].insurance = insurance;
          insurances.push(insurance);
        }
      } else {
        const newItem: CartItem = {
          product: product,
          metadata: {
            color: product?.color || '',
            storage: Number(
              `${product?.storage || 0}`.toUpperCase().replace('GB', '').replace('TB', '')
            ),
            model: product?.model || '',
          },
          displayDate: '',
          quantity: 1,
        };
        storeItems.push(newItem);
        if (insurance) {
          insurance.quantity = newItem.quantity;
          newItem.insurance = insurance;
          insurances.push(insurance);
        }
      }
    } catch (e) {
      console.log(e);
    }

    this.zone.run(() => {
      applyTransaction(() => {
        logAction('addNewProductToCart()');
      });
    });

    if (storeItems.length === 0 || this.store.getValue()[country].isLocked === true) {
      this.zone.run(() => {
        applyTransaction(() => {
          logAction('prepareNewCart()');
          this.store.updateLastSessionId(
            country,
            this.store.getValue()[country].sessionId
          );
        });
      });
      return this.cartApiService.createCartAPI(storeItems, insurances).pipe(
        catchError((err: unknown) => {
          const parsed = parseApiError(err);
          this.zone.run(() => {
            applyTransaction(() => {
              logAction('addNewProduct() - error');
            });
          });
          return throwError(() => parsed);
        }),
        tap((response: any) => {
          if (response) {
            this.zone.run(() => {
              applyTransaction(() => {
                logAction('addNewProduct() - done');
                this.store.toggleCartSaving(country, false);
                this.store.setCart(country, response);
              });
            });
          }
        })
      );
    } else {
      return this.cartApiService.updateCartAPI(storeItems, insurances).pipe(
        catchError((err: unknown) => {
          const parsed = parseApiError(err);
          console.log(parsed);
          this.zone.run(() => {
            applyTransaction(() => {
              logAction('updateCartItems() - rollback');
              // this.store.setCartItems(country, oldItems);
            });
          });
          if (parsed.code === 'ENTITY_NOT_FOUND') {
            return this.getCartItems();
          }
          return this.cartApiService.createCartAPI(storeItems, insurances).pipe(
            catchError((error: unknown) => {
              const parsedError = parseApiError(error);
              this.zone.run(() => {
                applyTransaction(() => {
                  logAction('createCartItems() - rollback - error');
                  this.store.toggleCartUpdating(country, true);
                  // this.store.setCartItems(country, oldItems);
                });
              });
              return throwError(() => parsedError);
            }),
            tap((response: any) => {
              if (response) {
                this.zone.run(() => {
                  applyTransaction(() => {
                    logAction('addNewProduct() - done');
                    this.store.toggleCartSaving(country, false);
                  });
                });
                this.store.setCart(country, response);
              }
            })
          );
        }),
        tap((response: any) => {
          if (response) {
            this.zone.run(() => {
              applyTransaction(() => {
                logAction('addNewProduct() - done');
                this.store.toggleCartSaving(country, false);
                this.store.setCart(country, response);
              });
            });
          }
        })
      );
    }
  }

  public removeInsuranceFromCartAsync(
    insurance: Insurance,
    trigger?: string
  ): Subscription {
    return this.removeInsuranceFromCart(insurance, trigger).subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public removeInsuranceFromCart(
    insurance: Insurance,
    trigger?: string
  ): Observable<boolean> {
    const country = this.akitaProductQuery.validateLocale().country;
    let insurances = this.store.getValue()[country].insurances;
    const items = this.store.getValue()[country].items;

    this.zone.run(() => {
      applyTransaction(() => {
        logAction('removeInsuranceFromCart()');
        logRemoveInsuranceFromCart(
          this.analytics.logEventWrapper,
          insurance,
          trigger || null
        );
      });
    });

    insurances = insurances.filter((i) => i.productId !== insurance.productId);

    // locked cart
    if (this.store.getValue()[country].isLocked === true) {
      this.zone.run(() => {
        applyTransaction(() => {
          logAction('prepareNewCart()');
          this.store.updateLastSessionId(
            country,
            this.store.getValue()[country].sessionId
          );
        });
      });
      return this.cartApiService.createCartAPI(items, insurances).pipe(
        catchError((err: unknown) => {
          const parsed = parseApiError(err);
          this.zone.run(() => {
            applyTransaction(() => {
              logAction('CreateCart() - rollback - error');
            });
          });
          return throwError(() => parsed);
        }),
        tap((response: any) => {
          if (response) {
            this.zone.run(() => {
              applyTransaction(() => {
                logAction('CreateCart() - done');
                this.store.toggleCartSaving(country, false);
                this.store.setCart(country, response);
              });
            });
          }
        })
      );
    } else {
      return this.cartApiService.updateCartAPI(items, insurances).pipe(
        catchError((err: unknown) => {
          const parsed = parseApiError(err);
          console.log(parsed);
          this.zone.run(() => {
            applyTransaction(() => {
              logAction('updateCartItems() - rollback');
            });
          });
          if (parsed.code === 'ENTITY_NOT_FOUND') {
            return this.getCartItems();
          }
          return this.cartApiService.createCartAPI(items, insurances).pipe(
            catchError((error: unknown) => {
              const parsedError = parseApiError(error);
              this.zone.run(() => {
                applyTransaction(() => {
                  logAction('createCartItems() - rollback - error');
                  this.store.toggleCartUpdating(country, true);
                });
              });
              return throwError(() => parsedError);
            }),
            tap((response: any) => {
              if (response) {
                this.zone.run(() => {
                  applyTransaction(() => {
                    logAction('createCartItems() - done');
                    this.store.toggleCartSaving(country, false);
                    this.store.setCart(country, response);
                  });
                });
              }
            })
          );
        }),
        tap((response: any) => {
          if (response) {
            this.zone.run(() => {
              applyTransaction(() => {
                logAction('updateCartItems() - done');
                this.store.toggleCartSaving(country, false);
              });
            });
            this.store.setCart(country, response);
          }
        })
      );
    }
  }

  public addInsuranceToCartAsync(insurance: Insurance, trigger?: string): Subscription {
    return this.addInsuranceToCart(insurance, trigger).subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public addInsuranceToCart(insurance: Insurance, trigger?: string): Observable<boolean> {
    const country = this.akitaProductQuery.validateLocale().country;
    let insurances: Array<Insurance> = JSON.parse(
      JSON.stringify(this.store.getValue()[country].insurances)
    );
    const items = this.store.getValue()[country].items;

    const targetItem = items.find(
      (item: CartItem) => item.product.id === insurance.productId
    );

    // check existing insurance linked to the product
    // equal quantity for product and insurance
    if (targetItem?.quantity) {
      if (insurances.findIndex((i) => i.productId === insurance.productId) !== -1) {
        insurances = insurances?.map((i) => {
          if (i.productId === insurance.productId) {
            insurance.quantity = targetItem?.quantity;
            // targetItem.insurance = insurance;
            i = insurance;
          }
          return i;
        });
      } else {
        insurance.quantity = targetItem?.quantity;
        targetItem.insurance = insurance;
        insurances.push(insurance);
      }
    }
    logAddInsuranceToCart(this.analytics.logEventWrapper, insurance, trigger || null);

    // locked cart
    if (this.store.getValue()[country].isLocked === true) {
      this.zone.run(() => {
        applyTransaction(() => {
          logAction('prepareNewCart()');
          this.store.updateLastSessionId(
            country,
            this.store.getValue()[country].sessionId
          );
        });
      });
      return this.cartApiService.createCartAPI(items, insurances).pipe(
        catchError((err: unknown) => {
          const parsed = parseApiError(err);
          this.zone.run(() => {
            applyTransaction(() => {
              logAction('CreateCart() - rollback - error');
            });
          });
          return throwError(() => parsed);
        }),
        tap((response: any) => {
          if (response) {
            this.zone.run(() => {
              applyTransaction(() => {
                logAction('CreateCart() - done');
                this.store.toggleCartSaving(country, false);
                this.store.setCart(country, response);
              });
            });
          }
        })
      );
    } else {
      return this.cartApiService.updateCartAPI(items, insurances).pipe(
        catchError((err: unknown) => {
          const parsed = parseApiError(err);
          console.log(parsed);
          this.zone.run(() => {
            applyTransaction(() => {
              logAction('updateCartItems() - rollback');
            });
          });
          if (parsed.code === 'ENTITY_NOT_FOUND') {
            return this.getCartItems();
          }
          return this.cartApiService.createCartAPI(items, insurances).pipe(
            catchError((error: unknown) => {
              const parsedError = parseApiError(error);
              this.zone.run(() => {
                applyTransaction(() => {
                  logAction('createCartItems() - rollback - error');
                  this.store.toggleCartUpdating(country, true);
                });
              });
              return throwError(() => parsedError);
            }),
            tap((response: any) => {
              if (response) {
                this.zone.run(() => {
                  applyTransaction(() => {
                    logAction('createCartItems() - done');
                    this.store.toggleCartSaving(country, false);
                    this.store.setCart(country, response);
                  });
                });
              }
            })
          );
        }),
        tap((response: any) => {
          if (response) {
            this.zone.run(() => {
              applyTransaction(() => {
                logAction('updateCartItems() - done');
                this.store.toggleCartSaving(country, false);
              });
            });
            this.store.setCart(country, response);
          }
        })
      );
    }
  }
  public addProductToCleanCart(
    product: Product,
    insurance?: Insurance | null
  ): Observable<boolean> {
    const country = this.akitaProductQuery.validateLocale(null, product.country).country;

    const newItem: CartItem = {
      product: product,
      metadata: {
        color: (product as any)?.color || (product as any).phoneMetadata?.color || '',
        storage: Number(
          `${
            (product as any)?.storage || (product as any).phoneMetadata?.storageInGB || 0
          }`
            .toUpperCase()
            .replace('GB', '')
            .replace('TB', '')
        ),
        model: (product as any)?.model || (product as any).phoneMetadata?.model || '',
      },
      displayDate: '',
      quantity: 1,
    };

    const newItems = [...new Array(0), newItem];
    let insuranceItems = null;
    if (insurance) {
      insuranceItems = [
        ...new Array(0),
        {
          insurance_id: insurance?.id || '',
          product_id: product.id,
          quantity: 1,
        },
      ];
      logAddInsuranceToCart(this.analytics.logEventWrapper, insurance, 'product-page');
    }

    return this.cartApiService.createCartAPI(newItems, insuranceItems).pipe(
      catchError((err: unknown) => {
        const parsed = parseApiError(err);
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('addListingToCart() - rollback - error');
            this.store.setCartItems(country, newItems);
          });
        });
        return throwError(() => parsed);
      }),
      tap((response: any) => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('addProductToCart()');
            this.store.setCart(country, response);
          });
        });
      })
    );
  }

  public increaseQtyFromCartAsync(id: string): Subscription {
    return this.increaseQtyFromCart(id).subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public increaseQtyFromCart(id: string): Observable<boolean> {
    const country = this.akitaProductQuery.validateLocale().country;
    const insurances = [...this.store.getValue()[country].insurances] || null;
    const oldItems = this.store.getValue()[country].items;

    const selectedItem = this.store
      .getValue()
      [country].items.find((item: CartItem) => item.product.id === id);

    // increase quantity
    if (selectedItem?.quantity) {
      selectedItem.quantity++;
      insurances?.map((insurance) => {
        if (insurance.productId === id) {
          insurance.quantity = selectedItem?.quantity;
          selectedItem.insurance = insurance;
        }
      });
    }

    this.zone.run(() => {
      applyTransaction(() => {
        logAction('increaseQtyFromCart()');
        this.analytics.setSessionUserDetailsIntoDataLayer();
        logAddToCart(
          this.analytics.logEventWrapper,
          this.analytics.firebaseRecordAddToCartADConversion,
          this.analytics.facebookTrackAddToCart,
          this.akitaProductQuery.getProduct(id),
          'increase_button'
        );
        if (selectedItem?.insurance) {
          logAddInsuranceToCart(
            this.analytics.logEventWrapper,
            selectedItem.insurance,
            'cart'
          );
        }
        // this.store.setCartItems(country, oldItems);
      });
    });

    if (this.store.getValue()[country].isLocked === true) {
      this.zone.run(() => {
        applyTransaction(() => {
          logAction('prepareNewCart()');
          this.store.updateLastSessionId(
            country,
            this.store.getValue()[country].sessionId
          );
        });
      });
      return this.cartApiService.createCartAPI(oldItems, insurances).pipe(
        catchError((error: unknown) => {
          const parsed = parseApiError(error);
          this.zone.run(() => {
            applyTransaction(() => {
              logAction('createCartItems() - error');
              this.store.toggleCartSaving(country, false);
              this.store.setCartSavingError(country, parsed);
              this.store.reset();
            });
          });

          return throwError(() => parsed);
        }),
        tap((response: any) => {
          if (response) {
            this.zone.run(() => {
              applyTransaction(() => {
                logAction('updateQtyItems() - done');
                this.store.toggleCartSaving(country, false);
                this.store.setCart(country, response);
              });
            });
          }
        })
      );
    } else {
      return this.cartApiService.updateCartAPI(oldItems, insurances).pipe(
        catchError((error: unknown) => {
          const parsed = parseApiError(error);
          this.zone.run(() => {
            applyTransaction(() => {
              logAction('updateQtyItems() - error');
              this.store.toggleCartSaving(country, false);
              this.store.setCartSavingError(country, parsed);
            });
          });

          if (parsed.code === 'ENTITY_NOT_FOUND') {
            return this.getCartItems();
          }
          return throwError(() => parsed);
        }),
        tap((response: any) => {
          if (response) {
            this.zone.run(() => {
              applyTransaction(() => {
                logAction('updateQtyItems() - done');
                this.store.toggleCartSaving(country, false);
                this.store.setCart(country, response);
              });
            });
          }
        })
      );
    }
  }

  public removeQtyFromCartAsync(id: string): Subscription {
    return this.removeQtyFromCart(id).subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public removeQtyFromCart(id: string): Observable<boolean> {
    const country = this.akitaProductQuery.validateLocale().country;
    let insurances = [...this.store.getValue()[country].insurances] || null;

    logRemoveFromCart(
      this.analytics.logEventWrapper,
      this.akitaProductQuery.getProduct(id),
      'increase_button'
    );

    let oldItems = this.store.getValue()[country].items;

    const selectedItem = this.store
      .getValue()
      [country].items.find((item: CartItem) => item.product.id === id);

    // decrease quantity
    if (selectedItem?.quantity && selectedItem?.quantity > 1) {
      selectedItem.quantity--;
      insurances?.map((insurance) => {
        if (insurance.productId === id) {
          insurance.quantity = selectedItem?.quantity;
          selectedItem.insurance = insurance;
          logRemoveInsuranceFromCart(this.analytics.logEventWrapper, insurance, 'cart');
        }
      });
    } else {
      oldItems = oldItems.filter((item: CartItem) => item.product.id !== id);
      insurances = insurances.filter((i) => i.productId !== id);
    }

    this.zone.run(() => {
      applyTransaction(() => {
        logAction('removeQtyFromCart()');
      });
    });

    if (oldItems.length === 0) {
      this.zone.run(() => {
        applyTransaction(() => {
          logAction('ResetCart()');
          this.cartApiService.saveCartId(null);
          this.store.resetCart(country);
        });
      });
      return of(true);
    } else if (this.store.getValue()[country].isLocked === true) {
      this.zone.run(() => {
        applyTransaction(() => {
          logAction('prepareNewCart()');
          this.store.updateLastSessionId(
            country,
            this.store.getValue()[country].sessionId
          );
        });
      });
      return this.cartApiService.createCartAPI(oldItems, insurances).pipe(
        catchError((error: unknown) => {
          const parsed = parseApiError(error);
          this.zone.run(() => {
            applyTransaction(() => {
              logAction('createCartItems() - error');
              this.store.toggleCartSaving(country, false);
              this.store.setCartSavingError(country, parsed);
              this.store.reset();
            });
          });

          return throwError(() => parsed);
        }),
        tap((response: any) => {
          if (response) {
            this.zone.run(() => {
              applyTransaction(() => {
                logAction('updateQtyItems() - done');
                this.store.toggleCartSaving(country, false);
                this.store.setCart(country, response);
              });
            });
          }
        })
      );
    } else {
      return this.cartApiService.updateCartAPI(oldItems, insurances).pipe(
        catchError((error: unknown) => {
          const parsed = parseApiError(error);
          this.zone.run(() => {
            applyTransaction(() => {
              logAction('updateQtyItems() - error');
              this.store.toggleCartSaving(country, false);
              this.store.setCartSavingError(country, parsed);
            });
          });
          if (parsed.code === 'ENTITY_NOT_FOUND') {
            return this.getCartItems();
          }

          return throwError(() => parsed);
        }),
        tap((response: any) => {
          if (response) {
            this.zone.run(() => {
              applyTransaction(() => {
                logAction('updateQtyItems() - done');
                this.store.toggleCartSaving(country, false);
                this.store.setCart(country, response);
              });
            });
          }
        })
      );
    }
  }

  public updateProtectionFromCartAsync(isProtected: boolean): Subscription {
    return this.updateProtectionFromCart(isProtected).subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public updateProtectionFromCart(isProtected: boolean): Observable<Cart | null> {
    const country = this.akitaProductQuery.validateLocale().country;
    const insurances = [...this.store.getValue()[country].insurances] || null;
    const oldItems = this.store.getValue()[country].items;

    this.zone.run(() => {
      applyTransaction(() => {
        logAction('updateProtectionFromCart()');
      });
    });

    if (oldItems.length === 0) {
      this.zone.run(() => {
        applyTransaction(() => {
          logAction('GETCart()');
        });
      });
      return this.getCartItems().pipe(
        switchMap(() =>
          this.cartApiService.updateCartAPI(oldItems, insurances, isProtected).pipe(
            catchError((error: unknown) => {
              const parsed = parseApiError(error);
              this.zone.run(() => {
                applyTransaction(() => {
                  logAction('updateQtyItems() - error');
                  this.store.toggleCartSaving(country, false);
                  this.store.setCartSavingError(country, parsed);
                });
              });
              if (parsed.code === 'ENTITY_NOT_FOUND') {
                return this.getCartItems();
              }

              return throwError(() => parsed);
            }),
            tap((response: any) => {
              if (response) {
                this.zone.run(() => {
                  applyTransaction(() => {
                    logAction('updateQtyItems() - done');
                    this.store.toggleCartSaving(country, false);
                    this.store.setCart(country, response);
                  });
                });
              }
            })
          )
        )
      );
    } else {
      return this.cartApiService.updateCartAPI(oldItems, insurances, isProtected).pipe(
        catchError((error: unknown) => {
          const parsed = parseApiError(error);
          this.zone.run(() => {
            applyTransaction(() => {
              logAction('updateQtyItems() - error');
              this.store.toggleCartSaving(country, false);
              this.store.setCartSavingError(country, parsed);
            });
          });
          if (parsed.code === 'ENTITY_NOT_FOUND') {
            return this.getCartItems();
          }

          return throwError(() => parsed);
        }),
        tap((response: any) => {
          if (response) {
            this.zone.run(() => {
              applyTransaction(() => {
                logAction('updateQtyItems() - done');
                this.store.toggleCartSaving(country, false);
                this.store.setCart(country, response);
              });
            });
          }
        })
      );
    }
  }

  public getCartItemsAsync(): Subscription {
    return this.getCartItems().subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public getCartItems(): Observable<Array<CartItem>> {
    const country = this.akitaProductQuery.validateLocale().country;
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('getCartItems()');
        this.store.toggleCartLoading(country, true);
        this.store.setCartLoadingError(null);
      });
    });

    return this.cartApiService.readCartItems().pipe(
      catchError((error: unknown) => {
        const parsed = parseApiError(error);

        if (parsed.code === 'CART_NOT_FOUND') {
          this.zone.run(() => {
            applyTransaction(() => {
              logAction('getCartItems() - done');
              this.store.toggleCartLoading(country, false);
            });
          });
          return [];
        } else {
          this.zone.run(() => {
            applyTransaction(() => {
              logAction('getCartItems() - error');
              this.store.toggleCartLoading(country, false);
              this.store.setCartLoadingError(country, parsed);
            });
          });
          return throwError(() => parsed);
        }
      }),
      tap((response: any) => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('getCartItems() - done');
            this.store.toggleCartLoading(country, false);
            this.store.setCart(country, response);
          });
        });
      })
    );
  }

  public createCartItems(
    items: Array<CartItem>,
    insuranceItems?: any | null
  ): Observable<boolean> {
    const country = this.akitaProductQuery.validateLocale().country;
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('createCartItems()');
        this.store.toggleCartSaving(country, true);
        this.store.setCartSavingError(null);
      });
    });

    return this.cartApiService.createCartItems(items, insuranceItems).pipe(
      catchError((error: unknown) => {
        const parsed = parseApiError(error);
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('createCartItems() - error');
            this.store.toggleCartSaving(country, false);
            this.store.setCartSavingError(country, parsed);
            this.store.reset();
          });
        });

        return throwError(() => parsed);
      }),
      tap((success: boolean) => {
        if (success) {
          this.zone.run(() => {
            applyTransaction(() => {
              logAction('createCartItems() - done');
              this.store.toggleCartSaving(country, false);
            });
          });
        } else {
          this.zone.run(() => {
            applyTransaction(() => {
              logAction('createCartItems() - error');
              this.store.toggleCartSaving(country, false);
              this.store.setCartSavingError(
                country,
                parseApiError(FAILED_TO_SAVE_CART_ERROR)
              );
            });
          });
        }
      })
    );
  }

  public updateCartId(id: string | null): void {
    const country = this.akitaProductQuery.validateLocale().country;
    if (id) {
      this.zone.run(() => {
        applyTransaction(() => {
          logAction('updateCartId() - done');
          this.store.updateCartId(country, id);
        });
      });
    }
  }

  public getAvailableProtectionAsync(id: string | null): Subscription {
    return this.getAvailableProtection(id).subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public getAvailableProtection(
    id: string | null
  ): Observable<PackageProtectionModel | null> {
    const country = this.akitaProductQuery.validateLocale().country;
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('getAvailableProtection()');
      });
    });

    if (!id) {
      return of(null);
    }

    return this.cartApiService.getAvailableProtection(id).pipe(
      catchError((error: unknown) => {
        const parsed = parseApiError(error);
        return throwError(() => parsed);
      }),
      tap((response: any) => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('getAvailableProtection() - done');
            this.store.setAvailableProtection(country, response);
          });
        });
      })
    );
  }
}
