export class DiscountInfo {
  public amount: number;
  public code: string;
  public currency: string;

  constructor() {
    this.amount = 0;
    this.code = '';
    this.currency = '';
  }
}

export const parseDiscountInfo = (data?: Record<string, any> | null): DiscountInfo => {
  const discount = new DiscountInfo();
  if (data) {
    discount.amount = Math.abs(Number(`${data?.amount || 0}`));
    discount.code = data.code || '';
    discount.currency = data.currency || '';
  }
  return discount;
};
