import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Params, Router } from '@angular/router';
import { isBefore } from 'date-fns';
import { configureScope } from '@sentry/browser';
import { HttpStatusCode } from '@app/shared/utils/http-status-codes';

import { Observable, Subscription, take } from 'rxjs';
import { environment } from '@environments/environment';
import { UserModel } from '@app/shared/models/api/user.model';
import { SentryUtil } from '@app/shared/utils/sentry.util';
import { WindowUtils } from '@app/shared/utils/window.util';
import { AuthAPIService } from '@app/akita/api/auth/services/auth.service';
import { PopsyDateParser } from '@app/shared/utils/api-date.parser';
import { AkitaAuthService } from '@app/akita/api/auth/state/auth.service';
import { AkitaAuthQuery } from '@app/akita/api/auth/state/auth.query';
import { GoogleAnalyticsService } from '../services/google-analytics.service';
import { AkitaRouterService } from '@app/akita/router/state/router.service';
import { AuthTokenModel } from '@app/shared/models/api/auth/auth-token.model';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private readonly subscriptions: Subscription;

  constructor(
    private readonly zone: NgZone,
    private readonly router: Router,
    private readonly akitaRouterService: AkitaRouterService,
    private readonly authAPIService: AuthAPIService,
    private readonly akitaAuthService: AkitaAuthService,
    private readonly akitaAuthQuery: AkitaAuthQuery,
    private readonly googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.akitaAuthService.checkCookieSession();
    this.subscriptions = new Subscription();
  }

  public canActivate(
    next: ActivatedRouteSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.akitaAuthService.checkCookieSession();

    const user: UserModel | null = this.akitaAuthQuery.user;
    const serverType: string = this.akitaAuthQuery.serverType;
    let isSignedIn = false;

    // Get the redirect URI and add it to sentry for debugging
    const redirect = decodeURI(next.queryParams.redirect || '');
    if (redirect) {
      configureScope((scope) => {
        scope.setTag('redirect', `${redirect || ''}`);
      });
    }

    // preserve params on redirect
    let params = { ...next.queryParams };
    if (redirect) {
      params = { ...params, redirect: encodeURI(redirect) };
    }

    if (next.data.view === 'LOGOUT') {
      configureScope((scope) => {
        scope.setUser({});
      });
      this.akitaAuthService.logout();

      if (typeof window !== 'undefined') {
        this.zone.runOutsideAngular(() => {
          setTimeout(() => {
            if (redirect) {
              WindowUtils.setLocationHref(`${redirect}`);
            } else {
              this.router.navigate(['/'], params).catch((error) => {
                console.log(error);
              });
            }
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          }, 500);
        });
        return false;
      }
    }

    let pid = null;
    let sec = null;
    if (user && user.oauth && user.oauth.publicId) {
      pid = user.oauth.publicId;
    }
    if (user && user.oauth && user.oauth.secret) {
      sec = user.oauth.secret;
    }

    // Check if the user has logged in already
    const cookieSession = this.akitaAuthQuery.cookieSession;
    if (cookieSession && cookieSession.pid !== pid && cookieSession.sec !== sec) {
      if (cookieSession.pid && cookieSession.sec) {
        const cookieUser = new UserModel();
        cookieUser.oauth.publicId = this.akitaAuthQuery.cookieSession.pid || '';
        cookieUser.oauth.secret = this.akitaAuthQuery.cookieSession.sec || '';

        return this.renewToken(next, cookieUser, redirect, params);
      } else {
        this.logout(next, params, redirect);
      }
    } else if (
      // Check if the current session is for the same API Server
      serverType === environment.api.url &&
      // Check if the user exists and has an access token and session info
      user &&
      user.oauth &&
      user.oauth.publicId &&
      user.oauth.secret
    ) {
      if (user.oauth.token) {
        const expiresAt =
          PopsyDateParser.parseApiDate('expires_at', 'expiresAt', user.oauth.token) ||
          new Date(0);
        const isExpired = isBefore(expiresAt, new Date());

        // Check if the token is still valid
        if (isExpired) {
          // Token is missing, try to fetch (reset-password 2nd step)
          return this.renewToken(next, user, redirect, params);
        } else {
          isSignedIn = true;
          this.akitaAuthService.refreshUserAsync();
          configureScope((scope) => {
            scope.setUser({
              id: user.id,
              username: user.firstName,
              email: user.email,
            });
          });

          next.data = { ...next.data, user: user, oauth: user.oauth };
          if (next.firstChild) {
            next.firstChild.data = {
              ...next.firstChild.data,
              user: user,
              oauth: user.oauth,
            };
          }

          if (next.data.step === 'SIGN_IN' || next.data.step === 'SIGN_UP') {
            if (redirect) {
              WindowUtils.setLocationHref(`${redirect}${user.oauth.token.accessToken}`);
              return false;
            } else {
              if (next.data.protected === true) {
                this.router.navigate(['login'], params).catch((error) => {
                  console.log(error);
                });
                return false;
              }
              return true;
            }
          }
        }

        // Token has expired, try to renew
      } else {
        return this.renewToken(next, user, redirect, params);
      }
    } else {
      if (user) {
        configureScope((scope) => {
          scope.setUser({});
        });
        this.akitaAuthService.logout();
      }
    }

    // If the user was not signed in
    if (!isSignedIn) {
      configureScope((scope) => {
        scope.setUser({});
      });
      if (next.data.step === 'SIGN_IN' || next.data.step === 'SIGN_UP') {
        return true;
      } else if (next.queryParams.token) {
        return this.fetchUserInfoSync(next, redirect, params);
      }

      if (next.data.protected === true) {
        this.router.navigate(['user', 'sign-in'], params).catch((error) => {
          console.log(error);
        });
      }
    }

    if (next.data.protected === true) {
      return isSignedIn;
    }

    return true;
  }

  public async fetchUserInfoSync(
    next: {
      data?: any | null;
      queryParams?: Params | null;
      firstChild?: { data: any } | null;
    },
    redirect: string,
    params?: Params
  ): Promise<boolean> {
    try {
      const userInfo = await new Promise(
        (
          resolve: (value: UserModel | null) => void,
          reject: (reason?: unknown) => void
        ) => {
          this.subscriptions.add(
            this.authAPIService
              .getUserInfoFromToken(next?.queryParams?.token || '')
              .pipe(take(1))
              .subscribe({
                next: resolve,
                error: reject,
              })
          );
        }
      );

      if (userInfo) {
        next.data = { ...next.data, user: userInfo, oauth: userInfo.oauth };
        if (next.firstChild) {
          next.firstChild.data = {
            ...next.firstChild.data,
            user: userInfo,
            oauth: userInfo.oauth,
          };
        }

        if (redirect && userInfo && userInfo.oauth && userInfo.oauth.token) {
          WindowUtils.setLocationHref(`${redirect}${userInfo.oauth.token.accessToken}`);
          return false;
        }

        this.akitaAuthService.userSignedIn(userInfo);
        this.akitaAuthService.refreshUserAsync();
        configureScope((scope) => {
          scope.setUser({
            id: userInfo.id,
            username: userInfo.firstName,
            email: userInfo.email,
          });
        });
        return true;
      }
    } catch (error) {
      configureScope((scope) => {
        scope.setUser({});
      });

      // 404 means user does not exist in this server (log-out)
      if (error && (error as any)?.status === HttpStatusCode.NOT_FOUND) {
        this.akitaAuthService.logout();

        if (next.data.protected === true) {
          this.akitaRouterService.navigate(['/', 'user', 'sign-in'], params);
          return false;
        }
        return true;
      } else {
        SentryUtil.reportException(error, false, () => (parsedError: string) => {
          this.googleAnalyticsService.appException(
            parsedError,
            'application',
            `${(error as any)?.status || '-'}`,
            false,
            'AUTH_GUARD',
            'AuthGuard -> canActivate'
          );
        });
      }
    }

    return Boolean(next.data.protected !== true);
  }

  public logout(
    next: ActivatedRouteSnapshot,
    params?: Params | null,
    redirect?: string | null
  ): boolean {
    configureScope((scope) => {
      scope.setUser({});
    });
    this.akitaAuthService.logout();

    if (redirect) {
      if (typeof window !== 'undefined') {
        this.zone.runOutsideAngular(() => {
          setTimeout(() => {
            WindowUtils.setLocationHref(`${redirect}`);
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          }, 500);
        });
      }
    } else if (next.data.protected === true) {
      this.router
        .navigate(['/', 'user', 'sign-in'], params || undefined)
        .catch((error) => {
          console.log(error);
        });
      return false;
    }
    return true;
  }

  public async renewToken(
    next: ActivatedRouteSnapshot,
    user: UserModel,
    redirect: string,
    params?: Params
  ): Promise<boolean> {
    try {
      const newUser: UserModel = UserModel.fromJson(user) || new UserModel();
      newUser.oauth.token = await new Promise(
        (
          resolve: (value: AuthTokenModel | null) => void,
          reject: (reason?: unknown) => void
        ) => {
          this.subscriptions.add(
            this.authAPIService
              .getAccessToken(user.oauth.publicId, user.oauth.secret)
              .pipe(take(1))
              .subscribe({
                next: resolve,
                error: reject,
              })
          );
        }
      );

      next.data = { ...next.data, user: newUser, oauth: newUser.oauth };
      if (next.firstChild) {
        next.firstChild.data = {
          ...next.firstChild.data,
          user: newUser,
          oauth: newUser.oauth,
        };
      }

      if (redirect && newUser.oauth && newUser.oauth.token) {
        WindowUtils.setLocationHref(`${redirect}${newUser.oauth.token.accessToken}`);
        return false;
      }

      this.akitaAuthService.userSignedIn(newUser);
      this.akitaAuthService.refreshUserAsync();
      configureScope((scope) => {
        scope.setUser({
          id: newUser.id,
          username: newUser.firstName,
          email: newUser.email,
        });
      });
      return true;
    } catch (error) {
      // 404 means user does not exist in this server (log-out)
      if ((error as any)?.status === HttpStatusCode.NOT_FOUND) {
        this.akitaAuthService.logout();
      }

      configureScope((scope) => {
        scope.setUser({});
      });

      if (next.data.protected === true) {
        this.akitaRouterService.navigate(['/', 'user', 'sign-in'], params);
        return false;
      }

      return true;
    }
  }
}
