<ng-container *transloco="let t">
  <div
    class="search-box-component popsy-button pill"
    [class.rtl]="rtlMode"
    [class.focus]="inFocus"
    [class.mobileL]="(biggerThan$ | async)?.mobileL"
    (click)="openSearchInput()">
    <popsy-icon-search-glass class="search-icon"></popsy-icon-search-glass>

    <div class="text" [class.rtl]="rtlMode">
      <ng-container *ngIf="searchType !== 'location'">
        <input
          data-clarity-unmask
          #searchField
          class="field search-field"
          [class.mobile]="(lessThan$ | async)?.mobileL && searchType !== 'location'"
          [class.focus]="inFocus"
          role="search"
          [attr.aria-label]="t('hint_search')"
          type="search"
          [placeholder]="t('hint_search')"
          [(ngModel)]="searchQuery"
          (input)="updateQueryFragments($event)"
          (focus)="switchFocus(true)"
          (focusout)="switchFocus(false)"
          (keydown.enter)="isFilteredHints ? search(searchQuery) : null" />
      </ng-container>
      <ng-container *ngIf="searchType !== 'location' && queryParams?.text">
        <a
          role="button"
          class="clear-search-button"
          [class.rtl]="rtlMode"
          [attr.aria-label]="t('aria_filter_disable')"
          [routerLink]="searchUrlFragment$ | async"
          [queryParams]="getClearSearchQuery()"
          [queryParamsHandling]="'merge'">
          <popsy-icon-remove class="icon closeIcon gray-01"></popsy-icon-remove>
        </a>
      </ng-container>
    </div>
  </div>

  <div
    *ngIf="(inFocus$ | async) && searchQuery.length && (autocomplete$ | async)?.length"
    class="autocomplete-wrapper popsy-card">
    <div
      class="suggestion"
      *ngFor="let hint of autocomplete$ | async; trackBy: trackBySuggestion"
      (click)="search(hint)">
      {{ hint }}
    </div>
  </div>
</ng-container>
