import { DOCUMENT, NgIf, AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  AfterViewInit,
  NgZone,
  HostListener,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationCancel,
  Params,
  RouterOutlet,
} from '@angular/router';
import {
  combineLatest,
  Observable,
  Subject,
  Subscription,
  map,
  takeUntil,
  of,
  switchMap,
  distinctUntilChanged,
} from 'rxjs';
import { Coordinate } from '@app/akita/api/location/models/coordinate.model';
import { MetaService } from '@app/shared/services/meta.service';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { WindowUtils } from '@app/shared/utils/window.util';

// Firebase
import { AkitaLocationQuery } from '@app/akita/api/location/state/location.query';
import { AkitaLocationService } from '@app/akita/api/location/state/location.service';

import { AkitaAuthQuery } from '@app/akita/api/auth/state/auth.query';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';
import { AkitaConfigurationService } from '@app/akita/configuration/state/configuration.service';
import { AkitaConfigurationQuery } from '@app/akita/configuration/state/configuration.query';
import { CONFIGURATION } from '@app/akita/configuration/models/configuration.model';
import { AkitaScreenQuery } from '@app/akita/screen/state/screen.query';
import { AkitaScreenService } from '@app/akita/screen/state/screen.service';
import { ScreenSizeInfoState } from '@app/akita/screen/state/screen.store';
import { AkitaRemoteConfigService } from '@app/akita/api/remote-config/state/remote-config.service';
import { AkitaUsersService } from '@app/akita/api/users/state/users.service';
import { AkitaAuthService } from '@app/akita/api/auth/state/auth.service';
import { UserTiming } from '@app/shared/utils/performance.utils';
import { AkitaOverlaysService } from '@app/akita/overlays/state/overlays.service';

import { WebSite, WithContext, SoftwareApplication, Organization } from 'schema-dts';
import { AkitaRouterService } from '@app/akita/router/state/router.service';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import { configureScope } from '@sentry/browser';
import { AkitaCartService } from '@app/akita/cart/state/cart.service';
import { isLanguageRtl } from '@app/shared/utils/url.utils';
import { PopsyViewName } from '@app/akita/router/models/router.state';
import { logOpenPage, logWindowUnloaded } from '../services/google-analytics.lazy';
import { AkitaRemoteConfigQuery } from '@app/akita/api/remote-config/state/remote-config.query';
import { InitTasksService } from '../services/init-tasks.service';
import { vhCheck } from '@app/vh-check/vh-check';
import { AkitaNewsletterService } from '@app/akita/api/newsletter/state/newsletter.service';
import { TidioService } from '@app/shared/services/tidio.service';
import { AkitaProductsService } from '@app/akita/api/products/state/products.service';
import { MSClarityService } from '@app/shared/services/msclarity.service';
import { environment } from '@environments/environment';
import { OmnisendService } from '@app/shared/services/omnisend.service';
import { AkitaOmnisendService } from '@app/akita/api/omnisend/state/omnisend.service';
import { PleaseWaitComponent } from '../../shared/components/please-wait/please-wait.component';
import { NgxJsonLdComponent } from '../../ngx-json-ld/ngx-json-ld.component';
import { ToolbarComponent } from '../../shared/components/toolbar/toolbar.component';
import { AnnouncementBarComponent } from '../../components/announcement-bar/announcement-bar.component';

const WAIT_BEFORE_SHOWING_CHAT = 2000;

const PERFORMANCE_MARK_NAME = 'Main View Init';
const PERFORMANCE_MARK_START = `${PERFORMANCE_MARK_NAME} - Start`;
const PERFORMANCE_MARK_END = `${PERFORMANCE_MARK_NAME} - End`;

const ROUTE_LOAD_PERFORMANCE_MARK_NAME = 'Route Load Init';
const ROUTE_LOAD_PERFORMANCE_MARK_START = `${ROUTE_LOAD_PERFORMANCE_MARK_NAME} - Start`;
const ROUTE_LOAD_PERFORMANCE_MARK_END = `${ROUTE_LOAD_PERFORMANCE_MARK_NAME} - End`;

const SUPPORTED_LOCALES: { [key: string]: string } = {
  en: 'us',
  // pt: 'br',
  // es: 'es',
  ar: 'sa',
  fr: 'fr',
};

export declare type WithWebsiteContext = WebSite & {
  '@context': 'https://schema.org';
};

@Component({
  selector: 'app-root',
  templateUrl: './app.template.html',
  styleUrls: ['./app.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoModule,
    NgIf,
    AnnouncementBarComponent,
    ToolbarComponent,
    RouterOutlet,
    NgxJsonLdComponent,
    PleaseWaitComponent,
    AsyncPipe,
  ],
})
export class ViewAppComponent implements OnInit, OnDestroy, AfterViewInit {
  public readonly sessionId$: Observable<string | null>;
  public viewName$: Observable<PopsyViewName>;
  public isInternalView$: Observable<boolean>;
  public showNotifications$: Observable<boolean>;
  public pageLoading: boolean;

  public readonly redirectUri$: Observable<string>;
  public readonly inFullScreenMode$: Observable<boolean>;
  public readonly queryParamsToKeep$: Observable<Params>;

  public readonly rtlMode$: Observable<boolean>;
  public userAskedAboutDomain: boolean;

  // Media Queries
  public biggerThan$: Observable<ScreenSizeInfoState>;

  // Google Structured Data Schema
  public schema: WithWebsiteContext = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: 'Popsy',
    description: 'free mobile app to Buy & Sell second hand products. Free, Easy & Fast!',
    url: 'https://www.popsy.app',
    potentialAction: {
      '@type': 'SearchAction',
      target: 'https://www.popsy.app/products?text={search_term_string}',
      'query-input': 'required name=search_term_string',
    } as any,
  };

  public companySchema: WithContext<Organization> = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Popsy',
    image: ['https://www.popsy.app/assets/google-places.png'],
    logo: 'https://www.popsy.app/assets/pwa/icon-512x512.png',
    url: 'https://www.popsy.app/',
    sameAs: [
      'https://itunes.apple.com/app/apple-store/id1387389249&ct=web&mt=8',
      'https://play.google.com/store/apps/details?id=com.mypopsy.android',
      'https://wa.me/5551989942929',
      'https://wa.me/19293101821',
      'https://wa.me/971562033924',
      'https://www.facebook.com/popsyapp',
      'https://twitter.com/popsyapp',
      'https://www.instagram.com/popsy.app/',
      'https://www.linkedin.com/company/popsy/',
    ],
    address: {
      '@type': 'PostalAddress',
      streetAddress: '1374 Avenida Paulista, 11 Andar',
      addressLocality: 'Sao Paulo',
      postalCode: '01310-100',
      email: 'support@popsy.app',
      telephone: '+5551989942929',
      addressCountry: {
        '@type': 'Country',
        name: 'BR',
      },
    },
  };

  public appSchema: WithContext<SoftwareApplication> = {
    '@context': 'https://schema.org',
    '@type': 'SoftwareApplication',
    name: 'Popsy',
    operatingSystem: 'Android,iOS',
    applicationCategory: 'ShoppingApplication',
    offers: {
      '@type': 'Offer',
      price: '0.00',
      priceCurrency: 'USD',
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: '3.9',
      reviewCount: '9985',
    },
  };

  public userInboxUrlFragment$: Observable<Array<string>>;
  public userNotificationsUrlFragment$: Observable<Array<string>>;

  public isLoggedIn$: Observable<boolean>;
  public isSearchView$: Observable<boolean>;
  public currentConditionSet$: Observable<string>;

  private readonly subscriptions: Subscription;

  private readonly destroySubject: Subject<void>;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly initTasksService: InitTasksService,
    private readonly zone: NgZone,
    private readonly router: Router,
    private readonly renderer: Renderer2,
    private readonly activatedRoute: ActivatedRoute,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly googleAnalytics: GoogleAnalyticsService,
    private readonly meta: MetaService,
    private readonly msClarityService: MSClarityService,
    private readonly translate: TranslocoService,
    private readonly akitaLocationQuery: AkitaLocationQuery,
    private readonly akitaLocationService: AkitaLocationService,
    private readonly akitaAuthQuery: AkitaAuthQuery,
    private readonly akitaCartService: AkitaCartService,
    private readonly akitaConfigurationService: AkitaConfigurationService,
    private readonly akitaConfigurationQuery: AkitaConfigurationQuery,
    private readonly akitaScreenQuery: AkitaScreenQuery,
    private readonly akitaScreenService: AkitaScreenService,
    private readonly akitaRemoteConfigService: AkitaRemoteConfigService,
    private readonly akitaRemoteConfigQuery: AkitaRemoteConfigQuery,
    private readonly akitaUsersService: AkitaUsersService,
    private readonly akitaAuthService: AkitaAuthService,
    private readonly akitaOverlaysService: AkitaOverlaysService,
    private readonly akitaRouterService: AkitaRouterService,
    private readonly akitaRouterQuery: AkitaRouterQuery,
    private readonly akitaNewsletterService: AkitaNewsletterService,
    private readonly akitaProductsService: AkitaProductsService,
    private readonly tidioService: TidioService,
    private readonly omnisendService: OmnisendService,
    private readonly akitaOmnisendService: AkitaOmnisendService
  ) {
    UserTiming.createMark(PERFORMANCE_MARK_START);
    this.destroySubject = new Subject<void>();
    this.subscriptions = new Subscription();
    this.subscriptions.add(this.initTasksService.initialize());
    this.userAskedAboutDomain = false;
    this.pageLoading = false;

    this.rtlMode$ = this.akitaRouterQuery.selectIsRtlLayout();
    this.isInternalView$ = this.akitaRouterQuery.selectIsInternalView();
    this.viewName$ = this.akitaRouterQuery.selectViewName();
    this.sessionId$ = this.akitaAuthQuery.selectSessionId();
    this.currentConditionSet$ = this.akitaRemoteConfigQuery.selectCurrentConditionSet();

    this.showNotifications$ = combineLatest([
      this.akitaRouterQuery.selectViewName(),
      this.akitaRouterQuery.selectViewStep(),
    ]).pipe(
      map(([viewName, viewStep]) =>
        Boolean(
          viewName !== 'DELIVERY' &&
            viewStep !== 'SIGN_UP' &&
            viewStep !== 'SIGN_IN' &&
            viewStep !== 'CHAT'
        )
      )
    );

    this.akitaAuthService.checkGoogleAuthInitialization();

    this.redirectUri$ = this.akitaRouterQuery
      .selectQueryParams()
      .pipe(map((queryParams: Params) => decodeURI(queryParams.redirect || '')));

    this.inFullScreenMode$ = this.akitaRouterQuery.selectInFullScreenMode();
    this.queryParamsToKeep$ = this.akitaRouterQuery.selectQueryParamsToKeep();

    this.biggerThan$ = this.akitaScreenQuery.selectBiggerThan();
    this.isLoggedIn$ = this.akitaAuthQuery.selectIsLoggedIn();

    this.userInboxUrlFragment$ = this.akitaRouterQuery.selectUserInboxUrlFragment();
    this.userNotificationsUrlFragment$ =
      this.akitaRouterQuery.selectUserNotificationsUrlFragment();

    this.isSearchView$ = this.akitaRouterQuery
      .selectViewName()
      .pipe(
        map((viewName: PopsyViewName) =>
          Boolean(viewName === 'ROOT' || viewName === 'CATEGORY')
        )
      );

    this.zone.runOutsideAngular(() => {
      this.subscriptions.add(this.akitaRemoteConfigService.getRemoteConfigAsync());
    });

    // Try to decide a language
    this.akitaConfigurationService.detectLanguage(
      CONFIGURATION.languageCode,
      this.akitaRouterQuery.requestURL
    );

    this.subscriptions.add(
      this.translate
        .selectTranslate('app_name_long')
        .pipe(takeUntil(this.destroySubject))
        .subscribe({
          next: (translation: string) => {
            if (translation !== 'app_name_long') {
              this.meta.setTitle('app_name_long', true);
              this.meta.setCanonical();
              const description = this.translate.translate('app_title');
              this.meta.setTags([
                { name: 'og:url', content: `${WindowUtils.getLocationHref() || ''}` },
                { name: 'og:description', content: `${description}` },
                { name: 'description', content: `${description}` },
              ]);

              this.schema.description = `${description}`;
              this.changeDetector.markForCheck();
            }
          },
        })
    );

    this.zone.runOutsideAngular(() => {
      this.subscriptions.add(
        combineLatest([
          this.translate.langChanges$,
          this.akitaRouterQuery.selectCountry(),
        ])
          .pipe(
            distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
            switchMap(([lang, country]) => {
              if (lang) {
                let locale = `${lang || 'en'}`.toLowerCase();
                if (country) {
                  locale = `${locale}-${(country || 'US').toUpperCase()}`;
                }
                return this.akitaUsersService.updateUserLanguage(locale);
              } else {
                return of(null);
              }
            }),
            takeUntil(this.destroySubject)
          )
          .subscribe({
            next: () => {},
            error: () => {},
          })
      );
    });

    if (this.akitaRouterQuery.isBrowser) {
      vhCheck({
        cssVarName: 'ios-gap',
      });
    }

    // Listen to Navigation End events
    this.subscriptions.add(
      this.router.events.pipe(takeUntil(this.destroySubject)).subscribe({
        next: ($event?: RouterEvent | null) => {
          if ($event instanceof NavigationStart) {
            UserTiming.createMark(ROUTE_LOAD_PERFORMANCE_MARK_START);
            this.pageLoading = true;
            this.changeDetector.markForCheck();
          } else if ($event instanceof NavigationEnd) {
            let route = this.activatedRoute.snapshot;
            while (route.firstChild) {
              route = route.firstChild;
            }

            this.akitaRouterService.onNavigationEnd(
              route.params,
              route.queryParams,
              route.data,
              route.fragment
            );
            this.onNavigationEnd();

            const viewName = (route.data || { analyticsName: '' }).analyticsName || '';
            this.msClarityService.setTag('app-version', environment.app.version);
            this.msClarityService.setTag('app-commit', environment.app.commit);
            this.msClarityService.setTag('app-branch', environment.app.branch);
            this.msClarityService.setTag('psid', this.akitaAuthQuery.sessionId);
            if (this.akitaAuthQuery.user?.id) {
              this.msClarityService.setTag('anonymous', 'No');
              this.msClarityService.setTag('user-id', this.akitaAuthQuery.user.id);
            } else {
              this.msClarityService.setTag('anonymous', 'Yes');
            }
            this.msClarityService.identifyUser(
              this.akitaAuthQuery.user?.id,
              this.akitaAuthQuery.sessionId,
              viewName
            );

            this.omnisendService.trackPageView(viewName);
            // this.omnisendService.increasePageViewCount();

            this.zone.runOutsideAngular(() => {
              this.subscriptions.add(
                this.akitaOmnisendService.identifyUserAsync({
                  id: this.akitaAuthQuery.user?.id,
                  email: this.akitaAuthQuery.user?.email,
                  firstName: this.akitaAuthQuery.user?.firstName,

                  country: this.akitaRouterQuery.country,
                  lang: this.translate.getActiveLang(),
                })
              );
            });

            this.zone.runOutsideAngular(() => {
              let pageName = `${viewName || ''}`;
              if (pageName === 'listing') {
                const listingId = route.params.id || route.queryParams.id || '';
                if (listingId) {
                  pageName = `listing-${listingId}`;
                }
              } else if (pageName === 'search') {
                const query = route.queryParams.text || '';
                if (query) {
                  pageName = `search-${query}`;
                }
              } else if (pageName === 'search_category') {
                const categoryId =
                  route.params.category || route.queryParams.category || '';
                if (categoryId) {
                  pageName = `search-category-${categoryId}`;
                }
              } else if (pageName === 'user') {
                const userId = route.params.id || route.queryParams.id || '';
                const fragment = route.fragment;
                if (userId) {
                  pageName = `user-${userId}`;
                } else {
                  pageName = `profile`;
                }

                if (fragment) {
                  pageName = `${pageName}-${fragment}`;
                }
              }

              if (viewName) {
                configureScope((scope) => scope.setTransactionName(viewName));
              }
              logOpenPage(
                this.googleAnalytics.recordPageView,
                this.googleAnalytics.firebaseSetCurrentScreenWrapper,
                pageName,
                viewName,
                $event.urlAfterRedirects
              );
            });

            this.pageLoading = false;
            this.changeDetector.markForCheck();

            UserTiming.createMark(ROUTE_LOAD_PERFORMANCE_MARK_END);
            UserTiming.measure(
              ROUTE_LOAD_PERFORMANCE_MARK_NAME,
              ROUTE_LOAD_PERFORMANCE_MARK_START,
              ROUTE_LOAD_PERFORMANCE_MARK_END
            );
          } else if ($event instanceof NavigationCancel) {
            this.pageLoading = false;
            this.changeDetector.markForCheck();

            UserTiming.createMark(ROUTE_LOAD_PERFORMANCE_MARK_END);
            UserTiming.measure(
              ROUTE_LOAD_PERFORMANCE_MARK_NAME,
              ROUTE_LOAD_PERFORMANCE_MARK_START,
              ROUTE_LOAD_PERFORMANCE_MARK_END
            );
          }
        },
        error: () => {},
      })
    );

    this.zone.runOutsideAngular(() => {
      this.subscriptions.add(
        this.translate.langChanges$.pipe(takeUntil(this.destroySubject)).subscribe({
          next: (lang: string) => {
            this.akitaConfigurationService.refreshLanguageMetadata(lang, lang);
            this.akitaConfigurationService.changeLanguage(lang);
            this.akitaScreenService.setLayoutDirection(
              isLanguageRtl(lang) ? 'rtl' : 'ltr'
            );
          },
        })
      );

      this.subscriptions.add(
        this.akitaAuthQuery
          .selectIsLoggedIn()
          .pipe(takeUntil(this.destroySubject))
          .subscribe({
            next: (isLoggedIn: boolean) => {
              if (isLoggedIn) {
                this.applicationStableActions();
              }
            },
          })
      );
    });

    /*  this.activeCurrency$ = combineLatest([
      this.akitaRouterQuery.selectQueryParams(),
      this.akitaLocationQuery.selectDeviceLocation(),
      this.akitaConfigurationQuery.selectLanguageCode(),
    ]).pipe(
      map(([queryParams, deviceLocation, langCode]) =>
        this.detectCurrency(queryParams, deviceLocation, langCode)
      )
    ); */

    /*  this.subscriptions.add(
      this.activeCurrency$.pipe(takeUntil(this.destroySubject)).subscribe({
        next: (selectedCurrency: string) => {
          const currencyInfo = getCurrencyInfo(selectedCurrency);
          // this.akitaConfigurationService.updateCurrency(currencyInfo);

          if (currencyInfo?.code) {
            // this.akitaPaymentService.setCurrency(currencyInfo.code);
          }
        },
      })
    ); */

    this.subscriptions.add(
      this.akitaRouterQuery
        .selectIsRtlLayout()
        .pipe(takeUntil(this.destroySubject))
        .subscribe({
          next: (isRtl: boolean) => {
            this.renderer.removeClass(this.document.body, isRtl ? 'ltr' : 'rtl');
            this.renderer.addClass(this.document.body, isRtl ? 'rtl' : 'ltr');
          },
        })
    );
  }

  public ngOnInit(): void {
    this.zone.runOutsideAngular(() => {
      let route = this.activatedRoute.snapshot;
      while (route.firstChild) {
        route = route.firstChild;
      }
      this.akitaRouterService.onNavigationEnd(
        route.params,
        route.queryParams,
        route.data
      );
      this.onNavigationEnd();

      this.subscriptions.add(
        this.akitaLocationQuery
          .selectDeviceLocation()
          .pipe(
            distinctUntilChanged((a, b) =>
              Boolean(a && b && a.latitude === b.latitude && a.longitude === b.longitude)
            ),
            takeUntil(this.destroySubject)
          )
          .subscribe({
            next: (coordinate?: Coordinate | null) => {
              this.zone.runOutsideAngular(() => {
                if (
                  coordinate &&
                  coordinate.country &&
                  coordinate.latitude !== 0 &&
                  coordinate.longitude !== 0 &&
                  (coordinate.precission === 'GPS' || coordinate.precission === 'IP')
                ) {
                  this.akitaLocationService.updateLocation(coordinate);
                }
              });
            },
          })
      );
    });
  }

  public ngAfterViewInit(): void {
    if (this.akitaRouterQuery.isBrowser) {
      this.zone.runOutsideAngular(() => {
        this.subscriptions.add(
          this.akitaProductsService.getAvailableProductLocaleInfoAsync()
        );

        setTimeout(() => {
          UserTiming.createMark(PERFORMANCE_MARK_END);
          UserTiming.measure(
            PERFORMANCE_MARK_NAME,
            PERFORMANCE_MARK_START,
            PERFORMANCE_MARK_END
          );

          this.applicationStableActions();

          this.zone.runOutsideAngular(() => {
            // Preload All the dialogs for Home page
            setTimeout(() => {
              this.akitaNewsletterService.showNewsletterIfNeeded();

              this.akitaOverlaysService.preloadMainDialogs();
              this.akitaAuthService.showOneTap();

              this.installTidio();
              this.installOmnisend();
              // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            }, 0);
          });
        }, 0);
      });
    } else {
      this.subscriptions.add(
        this.akitaProductsService.getAvailableProductLocaleInfoAsync()
      );
    }
  }

  private installTidio(): void {
    if (this.akitaRouterQuery.isBrowser) {
      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          this.subscriptions.add(
            this.tidioService
              .installTidio()
              .pipe(takeUntil(this.destroySubject))
              .subscribe({
                next: () => {},
                error: () => {},
              })
          );
        }, WAIT_BEFORE_SHOWING_CHAT);
      });
    }
  }

  private installOmnisend(): void {
    if (this.akitaRouterQuery.isBrowser) {
      this.zone.runOutsideAngular(() => {
        this.subscriptions.add(
          this.omnisendService
            .installOmnisend()
            .pipe(takeUntil(this.destroySubject))
            .subscribe({
              next: () => {},
              error: () => {},
            })
        );
      });
    }
  }

  public applicationStableActions(): void {
    this.zone.runOutsideAngular(() => {
      if (this.akitaAuthQuery.isLoggedIn) {
        const user = this.akitaAuthQuery.user;
        if (user && user.id) {
          this.subscriptions.add(this.akitaUsersService.getUserAsync(user.id));
        }

        // this.subscriptions.add(this.akitaDeliveriesService.getDeliveriesPageAsync(0));
      }

      if (this.akitaRouterQuery.isBrowser) {
        this.subscriptions.add(this.akitaCartService.getCartItemsAsync());
        // this.subscriptions.add(this.akitaShopifyCartService.fetchLastCheckoutAsync());
      }
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  public onWindowAboutToUnload(): void {
    logWindowUnloaded(this.googleAnalytics.logEventWrapper);
    this.stopSubscriptions();
  }

  public onNavigationEnd(): void {
    const routeData = this.akitaRouterQuery.routeData;

    this.zone.runOutsideAngular(() => {
      const requestURL = this.akitaRouterQuery.requestURL;

      // Try to refresh the language (since it is not always updated after route changes)
      this.akitaConfigurationService.detectLanguage(
        CONFIGURATION.languageCode,
        requestURL || ''
      );

      if (
        this.akitaRouterQuery.queryParams.lang &&
        SUPPORTED_LOCALES[this.akitaRouterQuery.queryParams.lang || ''] &&
        this.akitaConfigurationQuery.languageCode.locale !==
          this.akitaRouterQuery.queryParams.lang
      ) {
        routeData.locale = this.akitaRouterQuery.queryParams.lang;
        this.akitaConfigurationService.detectLanguage(
          this.akitaRouterQuery.queryParams.lang,
          requestURL
        );
      }

      const isLoggedIn = this.akitaAuthQuery.isLoggedIn;
      if (isLoggedIn) {
        this.akitaOverlaysService.preloadSignedInDialogs();
      }

      this.akitaAuthService.checkCookieSession();
    });
  }

  /* private detectCurrency(
    queryParams: Params,
    deviceLocation: Coordinate | null,
    langCode: {
      locale: string;
      countryCode: string;
      rtl: boolean;
    }
  ): string {
    // Get the currency code from the URl or the store
    let selectedCurrency = queryParams.currency;
    if (!selectedCurrency) {
      if (deviceLocation) {
        selectedCurrency =
          CURRENCY_BY_COUNTRY[(deviceLocation.country || 'us').toUpperCase()];
      } else {
        selectedCurrency =
          CURRENCY_BY_COUNTRY[(langCode.countryCode || 'us').toUpperCase()];
      }
    }
    console.log(queryParams, deviceLocation, langCode);
    console.log('selectedCurrency', selectedCurrency);
    return selectedCurrency;
  } */

  // eslint-disable-next-line rxjs-angular/prefer-takeuntil
  public ngOnDestroy(): void {
    this.stopSubscriptions();
  }

  private stopSubscriptions(): void {
    this.destroySubject.next();
    this.destroySubject.complete();

    this.akitaScreenService.stopMonitoringScreen();

    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
